.video-bg {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 0px;
  z-index: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}
