.dropdown {
  background-color: var(--ca-color);
  border-radius: 16px;
  padding: 15px;
  box-shadow: 1px 2px 4px rgba(127, 127, 127, 0.5);
  transition: background-color var(--trans-speed) ease-in-out, box-shadow var(--trans-speed) ease-in-out;
  position: absolute;
  top: 2.1em;
  width: max-content;
  transform: translateX(-46%);
  overflow: hidden;
  z-index: 1;

  .items {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

.menu-item {
  height: 2.1875em;
  display: flex;
  gap: 0.5em;
  align-items: center;
  padding: 0.5em 0.8em 0.5em 0.8em;
  justify-content: flex-start;
}
