.footer {
  z-index: 2;
  min-height: 50px;
  width: 100%;
  margin-top: 16px;
  background-color: var(--ba-color);
  transition: background-color var(--trans-speed) ease-in-out;
  display:flex;
  justify-content: center;
  bottom: 0;
}

.text-center {
  text-align: center;
  margin: 0px !important;
  display: flex;
  align-items: center;
}
