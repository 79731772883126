body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--ba-color);
}

:root {
  --nav-size: 3.125em;
  --font-size: 1em;
  --trans-speed: 0.4s;
  --img-bright: 1;

  --he-color: #E7DED2;
  --fo-color: #F7F4F0;
  --ba-color: #F7F4F0;
  --ca-color: #E6E3D7;
  --ti-color: #384720;
  --pa-color: #4B3E2E;
}

[data-theme="dark"] {
  --he-color: #62513C;
  --fo-color: #212522;
  --ba-color: #212522;
  --ca-color: #29352b;
  --ti-color: #F3F6ED;
  --pa-color: #F7F4F0;
  --img-bright: 0.9;
}

/* ---------------------------------------------------- */
/* Cards */
.basic-card {
  background-color: var(--ca-color);
  border-radius: 16px;
  padding: 16px;
  transition: background-color var(--trans-speed) ease-in-out;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.align-row {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.align-column {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.card-svg {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  filter: brightness(var(--img-bright)) drop-shadow(0px 0.0625em 0.25em rgb(127, 127, 127));
  transition: filter var(--trans-speed) ease-in-out;
}

/* ---------------------------------------------------- */
/* Containers */
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(232px, 1fr));
  grid-gap: 16px;

  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  align-content: flex-start; */
}

.main-container {
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}

/* ---------------------------------------------------- */
/* Buttons and Links */
a, button {
  font-family: "Vollkorn SC", serif;
  font-weight: bold;
  font-size: var(--font-size);
  text-decoration: none;
  color: #F3F6ED;
  background-image: linear-gradient(to bottom, #85A94B, #495D29);
  padding: 0.125em 0.625em;
  border-radius: 1em;
  text-align: center;
  /* border: 0px solid #4B3E2E; */
}

button {
  padding: 1px 10px !important;
  border: 0;
}

.locked {
  color: rgba(170, 173, 161, 0.80);
  background-image: linear-gradient(to bottom, rgba(48, 56, 37, 0.7), rgba(109, 128, 79, 0.9)) !important;
  box-shadow: 0px 0px 5px rgba(173, 197, 134, 0.5), inset 4px 4px 15px rgba(0, 0, 0, 0.5) !important;
  cursor: default !important;
}

.disabled {
  background-image: linear-gradient(to bottom, #273216, #647F39) !important;
  box-shadow: 0px 0px 5px rgba(173, 197, 134, 0.5), inset 4px 4px 15px rgba(0, 0, 0, 0.5) !important;
  cursor: default !important;
}

a:hover, button:hover {
  transition: var(--trans-speed) ease-in-out;
  box-shadow: 0px 0.0625em 0.25em rgb(127, 127, 127);
  filter: brightness(1.2);
}

.disabled:hover, .locked:hover {
  box-shadow: 0px 0px 5px rgba(173, 197, 134, 0.5), inset 4px 4px 15px rgba(0, 0, 0, 0.5) !important;
  filter: brightness(1) !important;
}

/* ---------------------------------------------------- */
/* Icon */
svg {
  height: calc(var(--font-size) * 1.45);
  width: calc(var(--font-size) * 1.45);
  /* fill: #F3F6ED !important; */
};

/* ---------------------------------------------------- */
/* insert fields */
input {
  font-family: "Lato", sans-serif;
  font-size: var(--font-size);
  color: #4B3E2E;
  background-color: #E7DED2;
  border: 0px solid #4B3E2E;
  border-radius: 16px;
  padding: 0.125em 0.625em;
}

/* ---------------------------------------------------- */
/* Texts */
h1 {
  font-family: "Vollkorn SC", serif;
  font-weight: 400;
  font-size: calc(var(--font-size) * 1.5);
  color: var(--ti-color);
  transition: color var(--trans-speed) ease-in-out;
  margin-bottom: 0px;
}
h2 {
  font-family: "Vollkorn SC", serif;
  font-weight: bold;
  font-size: calc(var(--font-size) * 1.4);
  color: #F3F6ED;
  background-image: linear-gradient(to bottom, #B3936D, #62513C);
  padding: 0.125em 0.625em;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 0px;
}
h3 {
  font-family: "Vollkorn SC", serif;
  font-weight: 400;
  font-size: calc(var(--font-size) * 1.3);
  color: var(--ti-color);
  transition: color var(--trans-speed) ease-in-out;
  margin-bottom: 0px;
}
h4 {
  font-family: "Vollkorn SC", serif;
  font-weight: 400;
  font-size: calc(var(--font-size) * 1.2);
  color: var(--ti-color);
  transition: color var(--trans-speed) ease-in-out;
  margin-bottom: 0px;
}
h5 {
  font-family: "Vollkorn SC", serif;
  font-weight: 400;
  font-size: calc(var(--font-size) * 1.1);
  color: var(--ti-color);
  transition: color var(--trans-speed) ease-in-out;
  margin-bottom: 0px;
}
h6 {
  font-family: "Vollkorn SC", serif;
  font-weight: 400;
  font-size: calc(var(--font-size) * 1);
  color: var(--ti-color);
  transition: color var(--trans-speed) ease-in-out;
  margin-bottom: 0px;
}
p {
  font-family: "Lato", sans-serif;
  font-size: calc(var(--font-size) * 0.9);
  color: var(--pa-color);
  transition: color var(--trans-speed) ease-in-out;
  margin-bottom: 0px;
}
