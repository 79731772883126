.toggle-container {
  position: absolute;
  top: -0.7em;
  right: 1em;
}

.toggle {
  visibility: hidden;
}

.toggle + label {
  display: flex;
  align-items: center;
  font-size: 0.8em;
  cursor: pointer;
  border-radius: 1.25em;
}

label:hover {
  transition: var(--trans-speed) ease-in-out;
  box-shadow: 0px 0.0625em 0.25em rgb(127, 127, 127);
  filter: brightness(1.1);
}

.toggle + label::before {
  content: "";
  height: 2.03125em;
  width: 3.984375em;
  border-radius: 1.25em;
  background-image: linear-gradient(to bottom, #85A94B, #495D29);
  transition: transform var(--trans-speed) ease-in-out;
}

.toggle + label::after {
  content: "☀️";
  padding-top: 0.15625em;
  padding-left: 0.078125em;
  text-align: center;
  padding-right: 0.078125em;
  height: 1.71875em;
  width: 1.71875em;
  border-radius: 1.25em;
  background-color: #5cd9ff;
  position:absolute;
  left: 0.16em;
  transition: transform var(--trans-speed) ease-in-out, background-color var(--trans-speed) ease-in-out, content var(--trans-speed) ease-in-out;
}

.toggle:checked + label::after {
  content: "🌙";
  padding-top: 0.15625em;
  padding-left: 0.078125em;
  transform: translateX(113%);
  background-color: #0c1445;
  transition: transform var(--trans-speed) ease-in-out, background-color var(--trans-speed) ease-in-out, content var(--trans-speed) ease-in-out;
}
