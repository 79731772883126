.big-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  /* max-width: 262px; */
}

.title-box {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 8px;
}

.fav-display {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 8px;
}

.center-position {
  display: flex;
  justify-content: center;
  align-items: center;
}
